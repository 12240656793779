import React from "react";

import Profile from "../../components/Profile";
import MenuOutline from "../../components/MenuOutline";

import FeedFavourite from "./FeedFavourite";
import FeedMain from "./FeedMain";
import FeedRockets from "./FeedRockets";
import FeedTags from "./FeedTags";

function Account() {
    let [page, setPage] = React.useState(0)

    const pages = [FeedMain, FeedTags, FeedRockets, FeedFavourite]
    const selections = [
        { action: () => { setPage(0) }, name: 'Лента' }, 
        { action: () => { setPage(1) }, name: 'Тэги' }, 
        { action: () => { setPage(2) }, name: 'Ракеты' }, 
        { action: () => { setPage(3) }, name: 'Избранное' }, 
    ]

    const SelectedPage = pages[page]

    return (
        <div className="bg-[#19191A] font-[500] min-h-screen">
            <Profile addHighlighted={true}></Profile>

            <div className="mt-[36px]"></div>

            <div className="mx-4 mb-3">
                <MenuOutline selections={selections} classApply={'px-2'} />
            </div>

            <SelectedPage></SelectedPage>

        </div>
    )
}

export default Account
