import React from "react"

import InputBase from "./generic/InputBase";

function InputUsername(props) {
    let v = props.hookGet()
    v = v ? v : ''

    return (
        <>
            { v.length ? (
                <>
                    <p className="text-white/40">
                        { props.placeholder }
                    </p>
                    <InputBase {...props} className={`border-white text-white ${props.className}`}
                        onChange={e => { props.hookSet(e.target.value) } } onBlur={ props.hookUpdate() } />
                </>
            ) : (
                <InputBase {...props} className={`border-violet text-violet placeholder-violet mt-[20px] ${props.className}`}
                    onChange={e => { props.hookSet(e.target.value) } } onBlur={ props.hookUpdate() } />
            )
            }
        </>
    )
}

export default InputUsername;
