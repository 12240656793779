import React from "react"

import ButtonBase from "./generic/ButtonBase";

function ButtonSecondary(props) {
    let classes = "bg-black text-white/40"
    return (
        <ButtonBase {...props} className={`${classes} ${props.className}`}> {props.children} </ButtonBase>
    )
}

export default ButtonSecondary;
