import React from "react";

import MenuBase from "./UI/generic/MenuBase";
import ButtonExchange from "./UI/ButtonExchange";
import ButtonExchangeActive from "./UI/ButtonExchangeActive";

function MenuExchange({ selections, classApply, className }) {
    return (
        <MenuBase className={className} selections={ selections } ButtonActive={ButtonExchangeActive} ButtonInactive={ButtonExchange} classApply={classApply} />
    )
}

export default MenuExchange
