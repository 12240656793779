import React from 'react';

function FAQLine({ name, text }) {
    return (
        <div className='flex mb-5'>
            <div className="w-[30vw] text-pixel">
                <p className="text-white bg-[#6233FF] py-[2px] ps-2 pe-1 w-fit">
                    { name }
                </p>
            </div>
            <p className="text-white text-[17px] max-w-[50vw]">
                { text }   
            </p>
        </div>
    )
}

export default FAQLine
