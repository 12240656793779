import React from "react"

import ButtonBase from "./generic/ButtonBase";

function ButtonExchange(props) {
    let classes = "bg-transparent text-white/40 w-auto px-4"
    return (
        <ButtonBase {...props} className={`${classes} ${props.className}`}> {props.children} </ButtonBase>
    )
}

export default ButtonExchange;
