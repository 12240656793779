import React from "react";
import { useNavigate } from "react-router";

import ButtonPrimary from "../../components/UI/ButtonPrimary";

import attention from '../../assets/icon/attention.svg'

function MnemoSave() {
    let navigate = useNavigate()
    let mnemonics = ['house', 'screen', 'cat', 'dog', 'wife', 'wolf', 'pencil', 'tiger', 'academy', 'bird', 'draft', 'ship']
    
    let leftColumn = []
    let rightColumn = []

    mnemonics.slice(0, 6).forEach(word => {
        let component = <p className="text-xl text-orange"> { word } </p>
        leftColumn.push(component)
    })
    mnemonics.slice(6, 12).forEach(word => {
        let component = <p className="text-xl text-orange"> { word } </p>
        rightColumn.push(component)
    })

    return (
        <div className="bg-[#010101] pt-12 h-screen">
            <div className="bg-[#19191A] rounded-[24px] px-8">

                <div className="pt-10">
                    <p className="text-white/40">
                        Кошелек создан!
                    </p>
                    <h1 className="text-white text-[24px]">
                        Ваши мнемоники
                    </h1>
                </div>
                
                <div className="mt-[72px] flex text-[24px] text-orange">
                    <div className="w-1/2">
                        { leftColumn }
                    </div>
                    <div className="w-1/2">
                        { rightColumn }
                    </div>
                </div>

                <div className="mt-[84px] flex">
                    <img src={ attention } alt="" className="m-1" />
                    <p className="text-white ms-6">
                        Запишите эти слова <br />
                        в надежном место
                    </p>
                </div>

                <ButtonPrimary className="mt-6 mb-[56px]" onClick={ () => { navigate('/account/') } }>
                    Продолжить
                </ButtonPrimary>

            </div>
        </div>
        
    )
}

export default MnemoSave
