import React from "react";
import axios from "axios";

import ButtonDark from "../../components/UI/ButtonDark";
import Card from "../../components/Card";

import { Store } from "../../Store";

function FeedTags() {
    const store = React.useContext(Store)

    let [updated, setUpdated] = React.useState(false)
    let [tokens, setTokens] = React.useState([])
    let [tags, setTags] = React.useState([])

    if (!updated) {
        axios.get(`${store.baseRoute}/get-feed/tags`)
        .then(r => {
            console.log(r.data)
            setTokens(r.data.tokens)
            setTags(r.data.tags)
            setUpdated(1)
        })
        return (<></>)
    }

    let tagsRender = []
    tags.forEach((tag, i) => {
        let tagRender = <ButtonDark className="!w-max !px-4"> { tag.name } </ButtonDark>
        tagsRender.push(tagRender)
    })

    let feedRender = []
    tokens.forEach((token, i) => {
        let tokenRender = <Card key={i} {...token}></Card>
        feedRender.push(tokenRender)
    })

    return (
        <>
            <div className="sticky top-0 left-0 bg-[#19191A]">
                
                <div className="overflow-x-scroll pt-2">
                    <div className="w-max ps-3">
                        <div className="flex w-max">
                            { tagsRender.slice(0, 4) }
                        </div>
                        <div className="flex w-max">
                            { tagsRender.slice(4, 8) }
                        </div>
                        <div className="flex w-max">
                            { tagsRender.slice(8, 12) }
                        </div>
                    </div>
                </div>
            </div>
            

            <div className={"mt-6 py-3"}>
                { feedRender }
            </div>
        </>
    )
}

export default FeedTags
