import React, { useEffect } from "react";

import MenuOutline from "./MenuOutline"
import ButtonPrimary from "./UI/ButtonPrimary";
import ButtonInactive from "./UI/ButtonInactive";

import close from "../assets/icon/x.svg"

function AccCheckout({ closeHook, isWithdrawingHook }) {
    let [isWithdrawing, setIsWithdrawing] = React.useState(isWithdrawingHook)
    let [currency, setCurrency] = React.useState(0)

    let sumRef = React.useRef()

    let selectionsMain = [
        { action: () => { setIsWithdrawing(0) }, name: 'Пополнить' },
        { action: () => { setIsWithdrawing(1) }, name: 'Вывести' },
    ]
    let selectionsCurrency = [
        { action: () => { setCurrency(0) }, name: 'TON' },
        { action: () => { setCurrency(1) }, name: 'USDC' },
    ]

    useEffect(() => {
        sumRef.current.focus()
    })

    return (
        <div className="absolute top-0 left-0 h-screen w-screen backdrop-blur-xl z-10">
            <div className="fixed bottom-0 bg-[#19191A] rounded-t-3xl w-full">
                <div className="flex">
                    <img src={close} className="ms-auto me-[14px] mt-[14px] mb-[2px] w-6 h-6" role="button" onClick={ closeHook } alt="" />
                </div>
                <div className="w-fit mx-auto">
                    <MenuOutline selections={selectionsMain} classApply={"!w-fit px-4"} />
                </div>

                <div className="mt-4 w-fit mx-auto">
                    <MenuOutline selections={selectionsCurrency} classApply={"!w-fit px-4"} />
                </div>

                <div className="mt-8 w-fit mx-auto">
                    <input ref={sumRef} type="text" className="bg-transparent text-white text-[24px] border-b border-white w-[200px] outline-0" />
                </div>

                <div className="mb-10 mt-12 flex">
                    <ButtonPrimary className="!w-fit px-[56px] mx-auto">
                        Продолжить
                    </ButtonPrimary>
                </div>
                
                
            </div>
            
        </div>
    )
}

export default AccCheckout
