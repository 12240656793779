import React from "react";

function TokenHolders({ token }) {
    let holdersRender = []
    token.holders.forEach(holder => {
        let newHolder = <>
            <div className="flex bg-white bg-opacity-5 rounded-xl px-4 py-5 mt-1">
                <p className=" text-violet truncate text-ellipsis">
                    {holder.address}
                </p>
                <p className="text-white ps-3 w-max whitespace-nowrap">
                    {holder.amount}% {holder.isBC ? "(BC)" : ""}
                </p>
            </div>
        </>
        holdersRender.push(newHolder)
    })
    return (
    <>
        <div className="mt-1">
            { holdersRender }
        </div>
    </>
    )
}

export default TokenHolders
