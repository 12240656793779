import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import ButtonPrimary from "../../components/UI/ButtonPrimary";
import ButtonDisabled from "../../components/UI/ButtonDisabled"
import InputMnemonic from "../../components/UI/InputMnemonic";

function MnemoRestore() {
    let [mnemonics, setMnemonics] = React.useState(['', '', '', '', '', '', '', '', '', '', '', ''])

    let navigate = useNavigate()

    let leftColumn = []
    let rightColumn = []

    // buffering words
    let m = [...mnemonics]

    function addField(word, i, column) {
        let hookSet = v => { m[i] = v }
        let hookGet = _ => { return m[i] }
        let hookUpdate = _ => { setMnemonics(m) }
        let component = <InputMnemonic key={i} hookGet={hookGet} hookSet={hookSet} hookUpdate={hookUpdate} defaultValue={word} />
        column.push(component)
    }

    mnemonics.slice(0, 6).forEach((word, i) => addField(word, i, rightColumn))
    mnemonics.slice(6, 12).forEach((word, i) => addField(word, i+6, leftColumn))

    function paste() {
        navigator.clipboard.readText()
        .then(mnemos => {
            let words = mnemos.split(/\r\n|\n|\r|\s/).filter(i => i)
            let newMnemonics = []
            for (let i = 0; i < Math.max(12, words.length); i++)
                newMnemonics.push(words[i])
            setMnemonics(newMnemonics)
        })
    }
    let canConfirm = true
    for (let i = 0; i < 12; i++) {
        if (!mnemonics[i])
            canConfirm = false
    }

    return (
        <div className="bg-[#010101] pt-12 h-screen" onPaste={ paste }>
            <div className="bg-[#19191A] rounded-[24px] px-8">

                <div className="pt-10">
                    <p className="text-white/40">
                        Восстановление кошелька
                    </p>
                    <h1 className="text-white text-[24px]">
                        Введите мнемоники
                    </h1>
                </div>
                
                <div className="mt-[72px] flex text-[24px] text-orange">
                    <div className="w-1/2">
                        { leftColumn }
                    </div>
                    <div className="w-1/2">
                        { rightColumn }
                    </div>
                </div>

                { canConfirm ? (
                    <ButtonPrimary className="mt-[68px] mb-[56px]" onClick={ () => navigate('/account') }>
                        Продолжить
                    </ButtonPrimary>
                    ) : (
                    <ButtonDisabled className="mt-[68px] mb-[56px]">
                        Продолжить
                    </ButtonDisabled>
                )}

            </div>
        </div>
        
    )
}

export default MnemoRestore
