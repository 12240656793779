import React from "react";

import MenuBase from "./UI/generic/MenuBase";
import ButtonOutline from "./UI/ButtonOutline";
import ButtonInactive from "./UI/ButtonInactive";

function MenuOutline({ selections, classApply, className }) {
    return (
        <MenuBase className={className} selections={ selections } ButtonActive={ButtonOutline} ButtonInactive={ButtonInactive} classApply={`${classApply} rounded-3xl !py-[10px]`} />
    )
}

export default MenuOutline
