import React from "react";

import MenuBase from "./UI/generic/MenuBase";
import ButtonSmooth from "./UI/ButtonSmooth";
import ButtonSecondary from "./UI/ButtonSecondary";

function MenuSmooth({ selections, classApply, className }) {
    return (
        <MenuBase className={className} selections={ selections } ButtonActive={ButtonSmooth} ButtonInactive={ButtonSecondary} classApply={classApply}/>
    )
}

export default MenuSmooth
