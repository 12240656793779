import React from "react";
import { useNavigate } from "react-router";
import { useTonConnectUI } from "@tonconnect/ui-react";

import ButtonViolet from "../../components/UI/ButtonViolet";
import ButtonDark from "../../components/UI/ButtonDark";

import pumpon from '../../assets/gif/gray_pumpon.gif'
import logo from '../../assets/icon/logo.svg'

function Login() {
    let navigate = useNavigate()
    const [tonConnectUI, setOptions] = useTonConnectUI();

    return (
        <div className="bg-[#010101]">
            <div className="h-screen bg-[#D3D1DC] rounded-[24px]">
                <div className="mx-auto pt-[104px] w-fit">
                    <img src={ pumpon } className="w-[212px] me-[10px]" alt="" />
                </div>

                <div className="mx-auto w-fit">
                    <img src={ logo } alt="" />
                </div>

                <div className="mt-[108px]">
                    <div className="w-fit mx-auto">
                        <ButtonDark className="!w-fit !px-8" onClick={() => tonConnectUI.openModal()}>
                            Подключить TON-кошелек
                        </ButtonDark>
                    </div>
                    <div className="w-fit mx-auto mt-2">
                        <ButtonViolet className="!w-fit !px-8" onClick={() => navigate('/mnemonics/save')}>
                            Создать кошелек
                        </ButtonViolet>
                    </div>
                </div>

                <div className="w-fit mx-auto mt-[44px]">
                    <p className="text-black/40" onClick={() => navigate('/mnemonics/restore')}>
                        Восстановить кошелек
                    </p>
                </div>

            </div>
        </div>
        
    )
}

export default Login
