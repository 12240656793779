import React from "react";
import axios from "axios";

import MenuSmooth from "../../components/MenuSmooth";
import Card from "../../components/Card";
import CardSmall from "../../components/CardSmall";

import viewSmall from "../../assets/icon/viewSmall.svg"
import viewDefault from "../../assets/icon/viewDefault.svg"

import { Store } from "../../Store";


function FeedMain() {
    const store = React.useContext(Store)

    let [updated, setUpdated] = React.useState(false)
    let [isSmall, setIsSmall] = React.useState(false)
    let [tokens, setTokens] = React.useState([])

    if (!updated) {
        axios.get(`${store.baseRoute}/get-feed/main`)
        .then(r => {
            console.log(r.data)
            setTokens(r.data.tokens)
            setUpdated(1)
        })
        return (<></>)
    }

    let selections = [
        { action: () => {}, name: 'Дефолт' },
        { action: () => {}, name: 'Гейнеры' },
        { action: () => {}, name: 'Капа' },
    ]

    let feedRender = []
    let TokenCard = [Card, CardSmall][Number(isSmall)]
    tokens.forEach((token, i) => {
        let tokenRender = <TokenCard key={i} {...token}></TokenCard>
        feedRender.push(tokenRender)
    })

    

    return (
        <>
            <div className="sticky top-0 left-0 bg-[#19191A] py-3">
                <div className="mx-4 flex">
                    <MenuSmooth selections={selections} className="gap-1" classApply={"px-4 py-[10px]"}></MenuSmooth>
                    <img src={isSmall ? viewSmall : viewDefault} className="ms-auto" alt="" role="button" onClick={() => setIsSmall(!isSmall)} />
                </div>
            </div>
            

            <div className={"mt-6 "+(isSmall ? "grid grid-cols-2 gap-1": "")}>
                { feedRender }
            </div>
        </>
    )
}

export default FeedMain
