import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from "axios";
import { useTonWallet, useTonConnectUI } from "@tonconnect/ui-react";

import { Store } from "./Store";

import './index.css';

import Feed from "./pages/Feed/Feed";

import Token from "./pages/Token/Token";
import TokenAdd from "./pages/TokenAdd/TokenAdd";

import Login from "./pages/Login/Login";
import MnemoRestore from "./pages/Login/MnemoRestore";
import MnemoSave from "./pages/Login/MnemoSave";

import Account from "./pages/Account/Account";
import FAQ from "./pages/FAQ"

// fits any loading screens perfectly even except default login
import LoginLoading from "./pages/Login/LoginLoading";

function App({webApp}) {
	let [updated, setUpdated] = React.useState(0)
	let [connected, setConnected] = React.useState(0)

	let [username, setUsername] = React.useState(webApp.WebAppUser ? webApp.WebAppUser.username : 'Славный гога')
	let [avatar, setAvatar] = React.useState('')

	// redirecting requests, if frontend is running on a dev server
	let baseRoute = (window.location.host == 'localhost:3000') ? 'http://localhost:3005' : ''


	// ton proof
	const [tonConnectUI] = useTonConnectUI();

	const wallet = useTonWallet() 
	console.log('on state change', wallet)
	if (wallet) console.log(wallet.connectItems)

	useEffect(() => {
		if (connected) return console.log('connected already')
		tonConnectUI.setConnectRequestParameters({ state: 'loading' })
		axios.get(`${baseRoute}/generate-payload`)
		.then(r => {
			let value = r.data.payload
			console.log(value)
			tonConnectUI.setConnectRequestParameters({state: 'ready', value})
			// console.log(wallet, wallet.connectItems?.tonProof)
			console.log('updating after message sent for signing...')
			setConnected(1)
			console.log('done')
		})
	}, [wallet])

	if (!updated) {
		axios.get(`${baseRoute}/get-user`)
		.then(r => {
			setUsername(r.data.username)
			setAvatar(r.data.avatar)
			setUpdated(1)
		})

		return (
			<LoginLoading>
				Загрузка...
			</LoginLoading>
		)
	}

	return (
		<Store.Provider value={{
			username,
			avatar,
			wallet,
			baseRoute,
			setUsername,
			setAvatar,
			// setWallet
		}}>
			<div className="text-sm">
				<BrowserRouter>
					<Routes>
						<Route path="*" element={<Feed />} />

						<Route path="token" element={<Token />} />
						<Route path="token-add" element={<TokenAdd />} />

						<Route path="login" element={<Login />} />
						<Route path="mnemonics/restore" element={<MnemoRestore />} />
						<Route path="mnemonics/save" element={<MnemoSave />} />

						<Route path="account" element={<Account />} />
						<Route path="faq" element={<FAQ />} />


					</Routes>
				</BrowserRouter>
			</div>
		</Store.Provider>
		
    );    
	
}

export default App;
