import React from "react";

// selection format is an array with choice names and functions, 
// that must be triggered on choice
// [ action<Function>, name<String> ]
function MenuBase({ selections, ButtonActive, ButtonInactive, classApply, className }) {
    let [select, setSelect] = React.useState(0)

    let menuRender = []
    selections.forEach((selection, i) => {
        let button
        if (i === select)
            button = <ButtonActive key={i} className={classApply}> { selection.name } </ButtonActive>
        else
            button = <ButtonInactive key={i} className={classApply} onClick={() => { setSelect(i); selection.action() }}> { selection.name } </ButtonInactive>
        menuRender.push(button)
    })

    return (
        <>
            <div className={`flex ${className}`}>
                { menuRender }
            </div>
        </>
    )
}

export default MenuBase
