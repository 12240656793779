import React from "react";

import { useNavigate } from "react-router";

import buyButton from "../assets/icon/buy.svg"

function CardSmall({ booster, isGainer, name, short, price, dev, marketcap, description }) {
    let navigate = useNavigate()

    return (
        <div className="mt-2 pb-8">
            <div className="w-full h-[calc(50vw-4px)] bg-black rounded-[8px]" onClick={() => { navigate('/token/') }}>
                { booster-1 ? (
                    <>
                        <div className="flex bg-orange text-white w-fit">
                            <p className="ms-1 py-[2px]"><span className="text-pixel">BOOSTER</span> x</p>
                            <span className="text-[24px]/[20px]">{booster}</span> 
                        </div>
                    </>
                ) : ""}
                { isGainer ? (
                    <>
                        <div className="flex bg-violet text-white w-fit">
                            <p className="ms-1 me-[2px] text-[15px] pb-[2px] pt-1 text-pixel"> GAINER </p>
                        </div>
                    </>
                ) : ""}
                
            </div>
            <div className="mt-4 px-3">
                <div className="flex">
                    <p className="text-white">
                        {name}
                    </p>
                    <p className="text-white ms-auto">
                        ${short}
                    </p>
                </div>

                <p className="text-white ms-auto">
                    ${price}
                </p>


                <div className="flex">
                    <div className="flex-wrap">
                        <p className="text-white/40 ms-auto">
                            /${marketcap}
                        </p>
                        <div className="flex-nowrap">
                            <p className="text-violet/100 bg-violet bg-opacity-[32%] px-1 w-fit">
                                {dev}
                            </p>
                        </div>
                        
                    </div>
                    <img src={buyButton} alt="" className="ms-auto" />
                </div>

                

                
            </div>
        </div>
    )
}

export default CardSmall
