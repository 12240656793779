import React, { useEffect } from "react"

import InputBase from "./generic/InputBase";

function InputData(props) {
    let [focused, setFocused] = React.useState(false)
    let inputRef = React.useRef()
    let v = props.hookGet()
    v = v ? v : ''

    useEffect(() => {
        if (focused) 
            inputRef.current.focus()
    }, [focused])

    return (
        <>
            { v.length || focused ? (
                props.error ? (
                    <>
                        <p className="text-[#F50F5C] mt-3">
                            { props.placeholder }
                        </p>
                        <InputBase {...props} forwardedRef={inputRef} className={`border-[#F50F5C] text-[#F50F5C] ${props.className}`} placeholder=''
                            onChange={e => { props.hookSet(e.target.value) } } onFocus={() => {setFocused(1)}} onBlur={ () => {setFocused(0); props.hookUpdate()} } />
                        <p className="text-[#F50F5C]">
                            { props.error }
                        </p>
                    </>
                ) : (
                    <>
                        <p className="text-white/40 mt-3">
                            { props.placeholder }
                        </p>
                        <InputBase {...props} forwardedRef={inputRef} className={`border-white text-white ${props.className}`} placeholder=''
                            onChange={e => { props.hookSet(e.target.value) } } onFocus={() => {setFocused(1)}} onBlur={ () => {setFocused(0); props.hookUpdate()} } />
                    </>
                )
                
            ) : (
                <InputBase {...props} forwardedRef={inputRef} className={`border-violet text-violet placeholder-violet mt-[32px] ${props.className}`}
                    onChange={e => { props.hookSet(e.target.value) } } onFocus={() => {setFocused(1)}} onBlur={ () => {setFocused(0); props.hookUpdate()} } />
            )
            }
        </>
    )
}

export default InputData;
