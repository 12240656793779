import React from "react";
import axios from "axios";

import ButtonDisabled from "../../components/UI/ButtonDisabled";
import ButtonDark from "../../components/UI/ButtonDark";
import InputUsername from '../../components/UI/InputUsername';

import avatarDefault from "../../assets/icon/avatar_default.svg"

import { Store } from "../../Store";


function AccSettings() {
    const store = React.useContext(Store)

    let fileRef = React.useRef()

    let [username, setUsername] = React.useState(store.username)
    let [avatarSource, setAvatarSource] = React.useState('')
    let [avatar, setAvatar] = React.useState(store.avatar ? store.avatar : avatarDefault)

    let [loading, setLoading] = React.useState(false)

    let style = { backgroundImage: `url(${avatar})` }

    function importData() {
		let input = fileRef.current;
        
		input.onchange = _ => { 
            let file = input.files[0]
            console.log(file)
            let clone = new File([file], file.name, {type: file.type})
            setAvatarSource(clone)
			setAvatar(URL.createObjectURL(file))
		}
		input.click();
	}

    // kinda unrestful, but simple
    function confirm() {
        setLoading(1)

        let formData = new FormData();
        if (avatarSource)
            formData.append('file', avatarSource)
        formData.append('data', { username })

        axios.post(`${store.baseRoute}/post-user`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(res => {
            if (!res.data.error) {
                store.setUsername(username)
                store.setAvatar(avatar)
            }
            setLoading(0)
            console.log('loaded')
        })
    }

    let u = `${username}`

    let hookSet = v => { u = v }
    let hookGet = _ => { return u }
    let hookUpdate = _ => { return () => { setUsername(u) } }
    
    return (
        <>
            <div className="bg-white bg-opacity-[6%] mx-2 rounded-[24px] pt-[92px]">
                <input ref={fileRef} type="file" style={{'display': 'none'}} accept="image/*" />
                <div className="withimage bg-black rounded-full w-[33vw] h-[33vw] mx-auto mb-[64px]" style={style}
                    onClick={importData}>

                </div>

                <div className="mx-8 pb-[36px]">
                    <InputUsername placeholder="Имя" defaultValue={username} hookGet={hookGet} hookSet={hookSet} hookUpdate={hookUpdate}></InputUsername>
                </div>

            </div>

            <div className="flex mt-4">
                { username ? (
                    <ButtonDark className="mx-auto !w-fit !px-[62px]" onClick={confirm}>
                        { loading ?  'Подождите...' : 'Сохранить'}
                    </ButtonDark>
                ) : (
                    <ButtonDisabled className="mx-auto !w-fit !px-[62px]">
                        Сохранить
                    </ButtonDisabled>
                ) }
            </div>
        </>
    )
}

export default AccSettings
