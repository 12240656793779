import React from "react";
import axios from "axios";

import Card from "../../components/Card";

import { Store } from "../../Store";

function FeedFavourites() {
    const store = React.useContext(Store)

    let [updated, setUpdated] = React.useState(false)
    let [tokens, setTokens] = React.useState([])

    if (!updated) {
        axios.get(`${store.baseRoute}/get-feed/favourites`)
        .then(r => {
            console.log(r.data)
            setTokens(r.data.tokens)
            setUpdated(1)
        })
        return (<></>)
    }

    let feedRender = []
    tokens.forEach((token, i) => {
        let tokenRender = <Card key={i} {...token}></Card>
        feedRender.push(tokenRender)
    })

    return (
        <>
            <div className={"mt-6"}>
                { feedRender }
            </div>
        </>
    )
}

export default FeedFavourites
