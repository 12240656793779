import React, { useEffect } from "react";
import { Parser } from "react-tiny-bbcode"

import close from "../assets/icon/x.svg"

function LegendModal({ closeHook, bbcode }) {

    return (
        <div className="absolute top-0 left-0 min-h-screen h-full w-screen backdrop-blur-xl z-10">
            <div className="mt-6 bg-[#19191A] rounded-3xl py-5 w-full">
                <div className="flex">
                    <img src={close} className="ms-auto me-[14px] mt-[14px] mb-[2px] w-6 h-6" role="button" onClick={ closeHook } alt="" />
                </div>

                <div className="px-3">
                    <Parser bbcode={bbcode}></Parser>
                </div>
                
            </div>
            
        </div>
    )
}

export default LegendModal
