import React from "react";

import pumpon from '../../assets/gif/purple_pumpon.gif'

function LoginLoading(props) {

    return (
        <div className="bg-[#010101]">
            <div className="h-screen bg-violet rounded-[24px]">
                <div className="mx-auto pt-[172px] w-fit">
                    <img src={ pumpon } className="w-[212px] me-[10px]" alt="" />
                </div>

                <div className="w-fit mx-auto mt-[140px]">
                    <p className="text-white text-[24px] text-center">
                        { props.children }
                    </p>
                </div>

            </div>
        </div>
        
    )
}

export default LoginLoading
