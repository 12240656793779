import React from "react";
import axios from "axios";
import { Parser } from "react-tiny-bbcode"

// import bbcode from "../../assets/mocks/image 70.png"

function TokenLegend({ token }) {
    return (
    <>
        {/* <img src={bbcode} alt="" className="w-full pt-4" /> */}
        <Parser bbcode={token.bbcode}></Parser>
    </>
    )
}

export default TokenLegend
