import React, { useEffect } from "react";
import axios from "axios";

import ButtonPrimary from "../../components/UI/ButtonPrimary";
import ButtonDark from "../../components/UI/ButtonDark";
import ButtonAllOps from "../../components/UI/ButtonAllOps";
import BalanceOperation from "../../components/UI/BalanceOperation";
import AccCheckout from "../../components/AccCheckout";

import ton from '../../assets/icon/ton.svg'
import arrowHide from '../../assets/icon/arrow-hide.svg'

import { Store } from "../../Store";

function AccBalance() {
    const store = React.useContext(Store)

    let [canHide, setCanHide] = React.useState(0)
    let [allOps, setAllOps] = React.useState(0)
    let [checkoutOpen, setCheckoutOpen] = React.useState(0)
    let [isWithdrawing, setIsWithdrawing] = React.useState(0)
    
    let [updated, setUpdated] = React.useState(false)
    let [operations, setOperations] = React.useState([])
    let [balance, setBalance] = React.useState([])

    if (!updated) {
        axios.get(`${store.baseRoute}/get-acc/balance`)
        .then(r => {
            let localCanHide = Number(r.data.operations.length > 5)
            
            setOperations(r.data.operations)
            setBalance(r.data.balance)
            setCanHide(localCanHide)
            setAllOps(!localCanHide)
            setUpdated(1)
        })
        return (<></>)
    }

    let opsRender = []
    let torender = operations
    console.log(canHide, allOps)
    if (!allOps)
        torender = operations.slice(0, 5)

    torender.forEach((op, i) => {
        let operation = <BalanceOperation date={op.date} name={op.name} quantity={op.quantity+' TON'} />
        opsRender.push(operation)
    })

    return (
        <>
            <div className="bg-[#D3D1DC] mx-2 rounded-[24px]">
                <p className="text-[84px]/[84px] px-3 pt-4 pb-2 text-end">
                    { balance }
                </p>

                <div className="flex">
                    <div className="text-pixel">
                        <p className="text-[13px] text-white/40 bg-violet w-fit px-1">
                            МНОЖИТЕЛЬ
                        </p>
                        <p className="text-white bg-violet px-1">
                            1 ПОИНТ/ЧАС
                        </p>
                        
                    </div>

                    <div className="ms-auto me-5 flex">
                        <p className="text-black me-0.5 text-[17px]">
                            TON
                        </p>
                        <img src={ ton } alt="" className="w-4 h-4 mt-1" />
                    </div>
                </div>

                <div className="mx-5 mt-[34px] pb-3">
                    
                    { opsRender }

                    <div className="mb-3"></div>

                    {
                        canHide ? (allOps ? (
                            <>
                                <ButtonAllOps onClick={() => { setAllOps(0); console.log('hiding') }}>
                                    <img src={arrowHide} alt="" className="mx-auto" />
                                </ButtonAllOps>
                            </>
                        ) : (
                            <>
                                <ButtonAllOps onClick={() => { setAllOps(1); console.log('showing') }}>
                                    Все - { operations.length }
                                </ButtonAllOps>
                            </>
                        )) : ""
                    }
                    

                </div>

            </div>

            <div className="flex gap-1 mt-5">
                <ButtonPrimary onClick={() => { setIsWithdrawing(0); setCheckoutOpen(1) }}>
                    Пополнить
                </ButtonPrimary>
                <ButtonDark onClick={() => {setIsWithdrawing(1); setCheckoutOpen(1) }}>
                    Вывести
                </ButtonDark>
            </div>
            
            { checkoutOpen ? (
                <AccCheckout closeHook={ () => setCheckoutOpen(0) } isWithdrawingHook={ isWithdrawing } />
            ) : "" }

        </>
    )
}

export default AccBalance
