import React from "react";
import { useNavigate } from "react-router";

function AccNavigation() {
    let navigate = useNavigate()

    return (
        <div className="fixed bottom-0 text-white pt-2 pb-6 px-[60px] w-full bg-[#19191A]">
            <div className="flex w-full">
                <div className="text-center w-full">
                    <a onClick={ () => navigate('/') } role="button">
                        Жетоны
                    </a>
                </div>
                <div className="text-center w-full">
                    <a onClick={ () => navigate('/token-add/') } role="button">
                        Dev
                    </a>
                </div>
                <div className="text-center w-full">
                    <a onClick={ () => navigate('/faq/') } role="button">
                        FAQ
                    </a>
                </div>

            </div>
        </div>
    )
}

export default AccNavigation
