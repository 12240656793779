import React from "react"

import graph from "../../assets/mocks/IMG_7413.png"

import { Store } from "../../Store"

function TokenMain({ token }) {

    return (
        <>
            <div className="mt-2 px-3">
                <p className="text-violet ">
                    Bonding curve
                </p>
                <div className="bg-black relative w-100">
                    <div className="pb-8 bg-violet me-auto" style={{ width: `${token.curve}%` }}>

                    </div>
                    <div className="absolute right-0 top-0 m-2 float-end mix-blend-difference text-violet text-[24px]">
                        { token.curve } %
                    </div>
                </div>

                <div className="grid grid-cols-2 mt-2">
                    <div className="">
                        <p className="text-white/40">
                            Поинты за покупку
                        </p>
                        <div className="bg-white bg-opacity-5 text-[24px]/[26px] text-white w-fit pt-[6px] py-[2px] px-1">
                            {token.pts} $PP
                        </div>
                    </div>
                    <div className="">
                        <p className="text-orange text-right">
                            Рост
                        </p>
                        <div className="bg-orange bg-opacity-20 text-[24px]/[26px] text-orange w-fit pt-[6px] py-[2px] px-1 float-right">
                            { (token.growth >= 0) ? '+' : '-'}{token.growth}%
                        </div>

                    </div>
                </div>
            </div>

            <div className="w-[100vw] h-[100vw] bg-black rounded-[20px] mt-8">
                {token.booster - 1 ? (
                    <>
                        <div className="flex bg-orange text-white w-fit">
                            <p className="ms-1 text-[15px] pb-[2px] pt-1"><span className="text-pixel">BOOSTER</span> x</p>
                            <span className="text-[32px]/[28px]">{token.booster}</span>
                        </div>
                    </>
                ) : ""}
                {token.isGainer ? (
                    <>
                        <div className="flex bg-violet text-white w-fit">
                            <p className="ms-1 me-[2px] text-[15px] pb-[2px] pt-1 text-pixel"> GAINER </p>
                        </div>
                    </>
                ) : ""}
            </div>

            <div className="px-3 mt-5">
                <div className="grid grid-cols-2">
                    <div className="">
                        <p className="text-white/40">
                            Price
                        </p>
                        <p className="text-white text-[24px]">
                            ${token.price}
                        </p>
                    </div>

                    <div className="">
                        <p className="text-white/40">
                            Dev
                        </p>
                        <p className="text-white text-[24px]">
                            {token.dev}
                        </p>
                    </div>
                </div>

                <div className="mt-5 border-s border-white/40">
                    <p className="text-white/40 ps-3">
                        {token.desc}
                    </p>
                </div>

            </div>

            <div className="mt-5 px-2">
                <div className="bg-white/[4%] rounded-[12px] grid grid-cols-2 p-3">
                    <div className="">
                        <p className="text-white/25">
                            Marketcap
                        </p>
                        <p className="text-white text-[24px]">
                            ${token.marketcap}
                        </p>
                    </div>
                    <div className="">
                        <p className="text-white/25">
                            Liquidity
                        </p>
                        <p className="text-white text-[24px]">
                            ${token.marketcap}
                        </p>

                    </div>
                    <div className="">
                        <p className="text-white/25">
                            Supply
                        </p>
                        <p className="text-white text-[24px]">
                            10/100
                        </p>

                    </div>
                </div>

                <div className="bg-white/[4%] rounded-[12px] p-3 mt-1">
                    <div className="">
                        <p className="text-white/25">
                            Contract
                        </p>
                        <p className="text-violet">
                            TRdYr8DdZyTvv9HseQomZV4iohmBEDCMFx
                        </p>
                    </div>
                </div>

                <div className="bg-white/[4%] rounded-[12px] p-3 mt-1">
                    <div className="">
                        <p className="text-white/25">
                            Сайт
                        </p>
                        <div className="">
                            <p className="text-violet">
                                aliceinwonderland.com
                            </p>
                            <div className="grid grid-cols-2 mt-4">
                                <p className="text-violet">
                                    Twitter–X
                                </p>
                                <p className="text-violet">
                                    Telegram
                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <img src={graph} alt="" className="mt-5 w-full pb-[55px]" />
        </>
    )
}

export default TokenMain
