import React from "react";

function ExchangeAdd({ value }) {
    return (
        <div className="bg-violet bg-opacity-80 text-white text-center py-3 w-[16.6vw] rounded-[23px]">
            +{value}
        </div>
    )
}

export default ExchangeAdd
