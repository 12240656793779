import React from "react";
import axios from "axios";

import Card from "../../components/Card";

import { Store } from "../../Store";

function FeedRockets() {

    const store = React.useContext(Store)

    let [updated, setUpdated] = React.useState(false)
    let [tokens, setTokens] = React.useState([])

    if (!updated) {
        axios.get(`${store.baseRoute}/get-feed/rockets`)
        .then(r => {
            console.log(r.data)
            setTokens(r.data.tokens)
            setUpdated(1)
        })
        return (<></>)
    }
    
    let feedRender = []
    tokens.forEach((token, i) => {
        let tokenRender = <Card key={i} {...token}></Card>
        feedRender.push(tokenRender)
    })

    return (
        <>
            <div className="sticky top-0 left-0 bg-[#19191A] py-3">
                <div className="mx-4 flex">
                    <p className="mx-auto w-fit">
                        🔥🚀🌝💀
                    </p>
                </div>
            </div>
            

            <div className={"mt-6"}>
                { feedRender }
            </div>
        </>
    )
}

export default FeedRockets
