import React from "react";
import axios from "axios";

import ButtonAllOps from "../../components/UI/ButtonAllOps";
import PointsOperation from "../../components/UI/PointsOperation";

import arrowHide from '../../assets/icon/arrow-hide.svg'

import { Store } from "../../Store";

function AccPoints() {
    const store = React.useContext(Store)

    let [canHide, setCanHide] = React.useState(0)
    let [allOps, setAllOps] = React.useState(0)

    let [updated, setUpdated] = React.useState(false)
    let [operations, setOperations] = React.useState([])
    let [balance, setBalance] = React.useState([])

    if (!updated) {
        axios.get(`${store.baseRoute}/get-acc/points`)
        .then(r => {
            let localCanHide = Number(r.data.operations.length > 5)
            
            setOperations(r.data.operations)
            setBalance(r.data.balance)
            setCanHide(localCanHide)
            setAllOps(!localCanHide)
            setUpdated(1)
        })
        return (<></>)
    }

    let opsRender = []
    let torender = operations
    if (!allOps)
        torender = operations.slice(0, 5)

    torender.forEach((op, i) => {
        let operation = <PointsOperation date={op.date} name={op.name} quantity={op.quantity+' $PP'} />
        opsRender.push(operation)
    })

    return (
        <>
            <div className="bg-[#F0E1D8] mx-2 rounded-[24px]">
                <p className="text-[84px]/[84px] px-3 pt-4 pb-2 text-end">
                    { balance }
                </p>

                <div className="flex">

                    <div className="ms-auto me-5 flex">
                        <p className="text-black me-0.5 text-[17px]">
                            $PP
                        </p>
                    </div>
                </div>

                <div className="mx-5 mt-[34px] pb-3">
                    
                    { opsRender }

                    <div className="mb-3"></div>

                    {
                        canHide ? (allOps ? (
                            <>
                                <ButtonAllOps onClick={() => { setAllOps(0); console.log('hiding') }}>
                                    <img src={arrowHide} alt="" className="mx-auto" />
                                </ButtonAllOps>
                            </>
                        ) : (
                            <>
                                <ButtonAllOps onClick={() => { setAllOps(1); console.log('showing') }}>
                                    Все - { operations.length }
                                </ButtonAllOps>
                            </>
                        )) : ""
                    }
                    

                </div>

            </div>

        </>
    )
}

export default AccPoints

