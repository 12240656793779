import React from "react"

function InputBase(props) {
    const {forwardedRef, ...rest} = props

    let classes = 'border-0 border-b bg-transparent text-[24px]/[24px] outline-0'

    return (
        <input {...props} ref={forwardedRef} className={`${classes} ${props.className}`} />
    )
}

export default InputBase;
