import React from "react";

import MenuExchange from "./MenuExchange";
import ExchangeAdd from "./UI/ExchangeAdd";

function Exchange() {
    let [isBuying, setIsBuying] = React.useState(true)

    let selections = [
        { action: () => { console.log('buy'); setIsBuying(true) }, name: 'Купить' },
        { action: () => { console.log('sell'); setIsBuying(false) }, name: 'Продать' },
    ]

    return (
        <div className="px-3">
            <div className="bg-violet bg-opacity-[80%] backdrop-blur-xl rounded-[40px] p-2 w-full">
                <div className="flex">
                    <MenuExchange selections={selections} />
                    <div className="flex border-b border-white ">
                        { isBuying ? (
                            <>
                                <input type="text" className="border-0 bg-transparent text-white text-[24px] outline-0 text-end w-[calc(100vw-298px)]" />
                                <p className="mt-5 text-white/40 ms-[6px]">
                                    TON
                                </p>
                            </>
                            
                        ) : (
                            <>
                                <input type="text" className="border-0 bg-transparent text-white text-[24px] outline-0 text-end w-[calc(100vw-260px)]" />
                            </>
                        )
                        }

                    </div>
                </div>
                
                { !isBuying ? (
                    <div className="flex px-3 mt-3 gap-1">
                        <ExchangeAdd value={5}></ExchangeAdd>
                        <ExchangeAdd value={20}></ExchangeAdd>
                        <ExchangeAdd value={50}></ExchangeAdd>
                        <ExchangeAdd value={100}></ExchangeAdd>
                        <ExchangeAdd value={500}></ExchangeAdd>
                    </div>
                        
                ) : "" }
            </div>
        </div>
    )
}

export default Exchange
