import React from "react"

import ButtonBase from "./generic/ButtonBase";

function ButtonExchangeActive(props) {
    let classes = "bg-transparent border border-white text-white rounded-[20px] w-auto px-4"
    return (
        <ButtonBase {...props} className={`${classes} ${props.className}`}> {props.children} </ButtonBase>
    )
}

export default ButtonExchangeActive;
