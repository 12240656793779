import React, { useEffect } from "react";
import autosize from 'autosize';

import ChatMessage from "../../components/ChatMessage";

import send from "../../assets/icon/send.svg"
import sendActive from "../../assets/icon/sendActive.svg"

function TokenChat({ token }) {
    let [focused, setFocused] = React.useState(0)
    let [data, setData] = React.useState('')
    let inputRef = React.useRef()

    useEffect(() => {
        if (focused) {
            inputRef.current.focus()
            autosize(inputRef.current)
        }
    }, [focused])

    let chatRender = []
    token.comments.forEach(msg => {
        let newMsg = <ChatMessage owner={msg.owner} text={msg.text}></ChatMessage>
        chatRender.push(newMsg)
    })

    return (
    <>
        <div className="mx-3">
            { chatRender }
        </div>

        {/* not a really neat decision for positioning, but it's fast */}
        { focused ? (
            <div className="fixed bottom-[0px] w-full px-3 z-10 py-2 bg-[#D3D1DC]">
                <div className="bg-black bg-opacity-5 backdrop-blur-xl rounded-xl pe-5 p-4 w-full flex">
                    <textarea rows={1} defaultValue={data} ref={inputRef} className="bg-transparent border-0 placeholder-black/40 outline-0 w-full" placeholder="Сообщение..." onFocus={() => { setFocused(1) }} onBlur={e => { setData(e.target.value); setFocused(0) }} />
                    <img src={sendActive} alt="" />
                </div>
            </div>
        ) : (
            <div className="fixed bottom-[98px] w-full px-3">
                <div className="bg-[#EBEBFFCC] backdrop-blur-xl rounded-[40px] pe-5 p-4 w-full flex">
                    <input type="text" defaultValue={data} ref={inputRef} className="bg-transparent border-0 placeholder-black/40 outline-0 w-full" placeholder="Сообщение..." onFocus={() => {setFocused(1)}} onBlur={() => {setFocused(0)}} />
                    <img src={send} alt="" />
                </div>
            </div>
        )}
        
    </>
    )
}

export default TokenChat
