import React from "react"

import InputBase from "./generic/InputBase";

function InputMnemonic(props) {
    let v = props.hookGet()
    v = v ? v : ''

    return (
        <>
            { v.length ? (
                <InputBase  className="border-orange/[20%] text-orange w-[135px]" {...props} 
                    onChange={e => { props.hookSet(e.target.value) } } onBlur={ props.hookUpdate } />
            ) : (
                <InputBase  className="border-violet/[32%] text-violet placeholder-violet w-[135px]" {...props} placeholder="------" 
                    onChange={e => { props.hookSet(e.target.value) } } onBlur={ props.hookUpdate } />
            )
            }
        </>
    )
}

export default InputMnemonic;
