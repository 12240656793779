import React from "react";

function ChatMessage({ owner, text }) {
    return (
    <>
        <div className="flex mt-4">
            <div className="">
                <div className="bg-black rounded-full me-4 w-9 h-9">
        
                </div>
            </div>

            <div className="border-b border-white/5">
                <h4 className="text-orange">
                    { owner }
                </h4>
                <p className="text-white pb-4">
                    { text }
                </p>
            </div>
        </div>
    </>
    )
}

export default ChatMessage
