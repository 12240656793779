import React from "react";
import { useNavigate } from "react-router";
import { Parser } from "react-tiny-bbcode"

import Profile from "../../components/Profile";
import InputData from "../../components/UI/InputData";
import ButtonViolet from "../../components/UI/ButtonViolet";
import ButtonDisabled from "../../components/UI/ButtonDisabled";
import ButtonInactive from "../../components/UI/ButtonInactive";
import LegendModal from "../../components/LegendModal";

import holder from "../../assets/icon/coverPlaceholder.svg"
import addViolet from "../../assets/icon/addViolet.svg"
import plusViolet from "../../assets/icon/plusViolet.svg"

function TokenAdd() {
    let [legendModalOpen, setLegendModalOpen] = React.useState(false)

    let navigate = useNavigate()

    // fields 
    let [cover, setCover] = React.useState(0)
    let [fields, setFields] = React.useState({
        name:    { name: 'Название', value: '' },
        symbol:  { name: '$Символ', value: '' },
        desc:    { name: 'Короткое описание', value: '' },
        website: { name: 'Сайт', value: '' },
        x:       { name: 'Twitter—X', value: '' },
        tg:      { name: 'Телеграм', value: '' },
        legend:  { name: 'Легенда', value: '' },
    })

    let f = {...fields}
    let fieldsRender = []

    // validation

    let canConfirm = true
    Object.entries(fields).forEach(field => {
        let key = field[0]
        let body = field[1]
        if (!body.value)
            canConfirm = false
        else {
            if (key == 'website') {
                try { 
                    new URL(body.value)
                    console.log('reverting website field error')
                    field.error = 0
                }
                catch {
                    field.error = 'invalid URL'
                    canConfirm = false
                }
            }
        }
    })
    console.log('can confirm: ', canConfirm)

    Object.entries(fields).forEach((field, i) => {
        let key = field[0]
        let body = field[1]
        let hookSet = v => { f[key].value = v }
        let hookGet = _ => { return f[key].value }
        let hookUpdate = _ => { setFields(f) }
        let component = <InputData className="w-full font-[400]" key={key} hookGet={hookGet} hookSet={hookSet} hookUpdate={hookUpdate} defaultValue={body.value} placeholder={body.name} error={body.error} />
        fieldsRender.push(component)
    })

    // cover
    let fileRef = React.useRef()

    function importData() {
		let input = fileRef.current;
		
		input.onchange = _ => { 
			setCover(URL.createObjectURL(input.files[0]))
		}
		input.click();
	}

    return (
        <div className="relative">
            <div className="bg-[#19191A] font-[500] min-h-screen pb-[60px]">
                
                <Profile></Profile>

                <input ref={fileRef} type="file" style={{'display': 'none'}} accept="image/*" />
                { cover ? (
                    <div className="mt-2 px-3">
                        <img src={cover} className="w-full rounded-[20px]" alt="" />
                        <button className="w-full flex bg-[#6233FF52] p-[6px] rounded-xl mt-3" onClick={importData}>
                            <img src={plusViolet} alt="" />
                            <p className="mx-auto text-[#8066FF] my-[2px]">
                                Загрузить другое
                            </p>
                        </button>
                    </div>
                ) : (
                    <div className="mt-2 flex">
                        <div className="w-fit mx-auto relative">
                            <img src={holder} alt="" />
                            <img src={addViolet} alt="" className="absolute top-[60px] left-[96px]" role="button" onClick={importData} />
                        </div>
                    </div>
                )}


                <div className="px-3">
                    <fieldset>
                        { fieldsRender }
                    </fieldset>
                    { fields.legend.value ? (
                        <ButtonDisabled className="mt-3" onClick={() => { setLegendModalOpen(1) }}>
                            Предпросмотр
                        </ButtonDisabled>
                    ) : "" }

                    <div className="mt-16 flex">
                    { canConfirm ? (
                        <ButtonViolet className="!w-fit !px-12 mx-auto">
                            Создать жетон
                        </ButtonViolet>
                    ) : (
                        <ButtonDisabled className="!w-fit !px-12 mx-auto">
                            Создать жетон
                        </ButtonDisabled>
                    ) }
                        
                    </div>
                    
                </div>

            </div>

            { legendModalOpen ? (
                <LegendModal closeHook={() => { setLegendModalOpen(0) }} bbcode={fields.legend.value}></LegendModal>
            ) : ""}
        </div>
    )
}

export default TokenAdd
