import React from "react";

import FAQLine from "../components/UI/FAQLine";

import back from "../assets/icon/back.svg"

function FAQ() {
    let content = [
        { name: 'STEP 1', text: 'Pick a coin that you like' },
        { name: 'STEP 2', text: 'Buy the coin on the bonding curve' },
        { name: 'STEP 3', text: 'Sell at any time to lock in your profits or losses' },
        { name: 'STEP 4', text: 'When enough people buy on the bonding curve it reaches a market cap of $69k' },
        { name: 'STEP 5', text: '$12k of liquidity is then deposited in raydium and burned' },
    ]

    let stepsRender = []
    content.forEach((line, i) => {
        let lineRender = <FAQLine key={i} name={line.name} text={line.text}></FAQLine>
        stepsRender.push(lineRender)
    })

    return (
        <div className="bg-[#19191A] min-h-screen px-3">
            <div className="flex text-center text-white w-full">
                <img src={back} className="mt-3" alt="" role='button' onClick={()=> window.history.back() } />
                <h1 className="mx-auto pe-[36px] text-[24px] mt-4">
                    FAQ
                </h1>
            </div>

            <div className="mt-5 border-s border-violet">
                <p className="ps-3 text-[#8066FF] ">
                Pump prevents rugs by making sure that all created tokens are safe. Each coin on pump is a fair-launch with no presale and no team allocation.
                </p>
            </div>

            <div className="mt-[72px]">
                { stepsRender }
            </div>
        </div>
    )
}

export default FAQ
