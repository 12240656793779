import React from "react";
import ReactDOM from "react-dom/client";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

import App from "./App";

let webApp = window.Telegram.WebApp ? window.Telegram.WebApp : {};

const manifestUrl = "https://mfmgtu-lt2.ru/tonconnect-manifest.json";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<TonConnectUIProvider manifestUrl={manifestUrl}>
        <App webApp={webApp} />
	</TonConnectUIProvider>
);
