import React from "react"

import ButtonBase from "./generic/ButtonBase";

function ButtonAllOps(props) {
    let classes = "text-black/40 bg-black bg-opacity-[6%] !py-[10px] mt-1"
    return (
        <ButtonBase {...props} className={`${classes} ${props.className}`}> {props.children} </ButtonBase>
    )
}

export default ButtonAllOps;
