import React from "react";

import Profile from "../../components/Profile";
import MenuOutline from "../../components/MenuOutline";
import AccNavigation from "../../components/UI/AccNavigation";

import AccBalance from "./AccBalance";
import AccPoints from "./AccPoints";
import AccSettings from "./AccSettings";

function Account() {
    let [page, setPage] = React.useState(0)

    const pages = [AccBalance, AccPoints, AccSettings]
    const selections = [
        { action: () => { setPage(0) }, name: 'Баланс' }, 
        { action: () => { setPage(1) }, name: 'Поинты' }, 
        { action: () => { setPage(2) }, name: 'Настройки' }, 
    ]

    const SelectedPage = pages[page]

    return (
        <div className="bg-[#19191A] font-[500] min-h-screen">
            <Profile avatarHighlighted={true} addHighlighted={true}></Profile>

            <div className="mt-[36px]"></div>

            <div className="mx-[42px]">
                <MenuOutline selections={selections} classApply={'!px-4'} />
            </div>
            

            <div className="mb-[20px]"></div>

            <SelectedPage></SelectedPage>

            <AccNavigation></AccNavigation>

            {/* nearly equal to acc navigation height */}
            <div className="pb-[64px]"></div>
        </div>
    )
}

export default Account
