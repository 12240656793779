import React from "react"

function PointsOperation({ date, name, quantity }) {

    return (
        <div className="flex">
            <div className="flex gap-[10px]">
                <p className="text-black/100 opacity-40">
                    { date }
                </p>
                <p className="text-black/100 opacity-40">
                    { name }
                </p>
            </div>

            <div className="flex ms-auto gap-[6px]">
                <p className="text-black/100">
                    { quantity }
                </p>
            </div>
        </div>
    )
}

export default PointsOperation;
