import React from "react"

import ButtonBase from "./generic/ButtonBase";

function ButtonOutline(props) {
    let classes = "bg-transparent border border-orange text-orange"
    return (
        <ButtonBase {...props} className={`${classes} ${props.className}`}> {props.children} </ButtonBase>
    )
}

export default ButtonOutline;
