import React from "react"

function ButtonBase(props) {
    let classes = 'rounded-3 py-3 w-full rounded-2xl'
    return (
        <button {...props} className={`${props.className} ${classes}`} > {props.children} </button>
    )
}

export default ButtonBase;
