import React from "react";

function TokenTranses({ token }) {
    let transesRender = []
    token.transes.forEach(transaction => {
        let newTransaction = <>
            <div className="flex bg-white bg-opacity-5 rounded-xl px-4 py-5 mt-1">
                <p className=" text-orange truncate text-ellipsis">
                    {transaction.address}
                </p>
                <p className="text-white ps-3 w-max whitespace-nowrap">
                    {transaction.type}
                </p>
            </div>
        </>
        transesRender.push(newTransaction)
    })
    return (
    <>
        <div className="mt-1">
            { transesRender }
        </div>
    </>
    )
}

export default TokenTranses
