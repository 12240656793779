import { createContext } from "react";

export const Store = createContext({
    // user data
    username: '',
    avatar: '',
    wallet: '',

    // settings
    baseRoute: '',

    // state setters
    setUsername: (v) => { this.username = v },
    setAvatar: (v) => { this.avatar = v },
    setWallet: (v) => { this.wallet = v },
})
