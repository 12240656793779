import React from "react";
import axios from "axios";

import Exchange from "../../components/Exchange"
import MenuSmooth from "../../components/MenuSmooth";

import TokenMain from "./TokenMain";
import TokenLegend from "./TokenLegend";
import TokenChat from "./TokenChat";
import TokenHolders from "./TokenHolders";
import TokenTranses from "./TokenTranses";

import back from "../../assets/icon/back.svg"

import { Store } from "../../Store";

function Token() {
    let store = React.useContext(Store)

    let [page, setPage] = React.useState(0)
    let [token, setToken] = React.useState(0)

    const urlParams = new URLSearchParams(window.location.search)
    let id = urlParams.get('id')

    if (!token) {
        console.log('updating token')
        axios.get(`${store.baseRoute}/get-token/main?id=${id}`)
        .then(r => {
            if (!r.data.error) {
                setToken(r.data)
            }
        })
        return(<></>)
    }

    const pages = [TokenMain, TokenLegend, TokenChat, TokenHolders, TokenTranses]
    const selections = [
        { action: () => { setPage(0) }, name: 'Инфо' }, 
        { action: () => { setPage(1) }, name: 'Легенда' }, 
        { action: () => { setPage(2) }, name: 'Чат' }, 
        { action: () => { setPage(3) }, name: 'Холдеры' }, 
        { action: () => { setPage(4) }, name: 'Транзакции' }, 
    ]

    const SelectedPage = pages[page]    

    return (
        <>
            <div className="bg-[#19191A] font-[500] min-h-screen">
                <div className="flex text-center text-white w-full px-3 bg-[#19191A] sticky top-0 pb-2">
                    <img src={back} className="mt-3" alt="" role='button' onClick={() => window.history.back()} />
                    <h1 className="mx-auto text-[24px] mt-4">
                        {token.name}
                    </h1>
                    <p className="text-white/40 mt-[18px] me-3">
                        ${token.short}
                    </p>
                </div>
                
                <div className="pb-40">
                    <SelectedPage token={token}></SelectedPage>
                </div>
                

                <div className=" fixed bottom-0 w-full">
                    {/* do not show exchange widget in chats */}
                    { page === 2 ? "" : (<Exchange></Exchange>) }
                    

                    <div className="bg-black overflow-x-scroll w-full mt-2">
                        <div className="w-max p-3">
                            <MenuSmooth selections={selections} classApply="!px-3 !py-[10px] !rounded-5"></MenuSmooth>
                        </div>
                    </div>

                </div>
            </div>
            
        </>
    )
}

export default Token
