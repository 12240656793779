import React from "react"

import ButtonBase from "./generic/ButtonBase";

function ButtonInactive(props) {
    let classes = "text-white/100 bg-transparent"
    return (
        <ButtonBase {...props} className={`${classes} ${props.className}`}> {props.children} </ButtonBase>
    )
}

export default ButtonInactive;
