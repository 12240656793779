import React from "react";

import { useNavigate } from "react-router";

import ButtonDark from "./UI/ButtonDark";
import ButtonViolet from "./UI/ButtonViolet";

function Card({ id, booster, isGainer, name, short, price, dev, marketcap, description }) {
    let navigate = useNavigate()

    return (
        <div className="pb-6">
            <div className="w-[100vw] h-[100vw] bg-black rounded-[20px] mt-2 " onClick={() => { navigate(`/token?id=${id}`) }}>
                { booster-1 ? (
                    <>
                        <div className="flex bg-orange text-white w-fit">
                            <p className="ms-1 text-[15px] pb-[2px] pt-1"><span className="text-pixel">BOOSTER</span> x </p>
                            <span className="text-[32px]/[28px]"> {booster}</span> 
                        </div>
                    </>
                ) : ""}
                { isGainer ? (
                    <>
                        <div className="flex bg-violet text-white w-fit">
                            <p className="ms-1 me-[2px] text-[15px] pb-[2px] pt-1 text-pixel"> GAINER </p>
                        </div>
                    </>
                ) : ""}
            </div>

            <div className="mt-4 px-3">
                <div className="flex">
                    <h4 className="text-white text-[24px]/[24px] font-[400]">
                        {name}
                    </h4>
                    <p className="text-white ms-auto mt-2">
                        ${short}
                    </p>
                </div>

                <div className="flex">
                    <p className="text-white/40">
                        Price
                    </p>
                    <p className="text-white ms-auto">
                        ${price}
                    </p>
                </div>

                <div className="flex">
                    <p className="text-white/40">
                        Dev
                    </p>
                    <p className="text-violet/100 ms-auto bg-violet bg-opacity-[32%] px-1">
                        {dev}
                    </p>
                </div>

                <div className="flex">
                    <p className="text-white/40">
                        Marketcap
                    </p>
                    <p className="text-white ms-auto">
                        ${marketcap}
                    </p>
                </div>

                <div className="border-s border-white/40 mt-3">
                    <p className="text-white/40 ps-2">
                        {description}
                    </p>
                </div>

                <div className="flex mt-5 gap-1">
                    <ButtonDark>
                        В избранное
                    </ButtonDark>
                    <ButtonViolet>
                        Пополнить
                    </ButtonViolet>
                </div>
                
            </div>
        </div>
    )
}

export default Card
