import React from "react"
import { useNavigate } from "react-router"

import addButton from "../assets/icon/add.svg"
import addDisabled from "../assets/icon/addDisabled.svg"
import avatarDefault from "../assets/icon/avatar_default.svg"

import { Store } from "../Store"


function Profile({ avatarHighlighted, addHighlighted }) {
    let store = React.useContext(Store)
    let navigate = useNavigate()

    let avatar = store.avatar ? store.avatar : avatarDefault
    let style = { backgroundImage: `url(${avatar})` }

    return (
        <>
            <div className="flex">

                <div className="flex" onClick={ () => { if (!avatarHighlighted) navigate('/account/') } }>

                    <div className={"withimage w-12 h-12 rounded-full bg-black m-2"+(avatarHighlighted ? " border border-orange" : "")}
                        style={style}>

                    </div>

                    <p className={"m-1 mt-3 "+(avatarHighlighted ? "text-orange" : "text-white/40")}>
                        { store.username }
                    </p>
                </div>

                <div className="text-right flex ms-auto flex-wrap">
                    <div className="text-white/40 mt-3">
                        <p>
                            TON: 0.00 (0.00)
                        </p>
                        <p>
                            Points: 0.00
                        </p>
                    </div>
                    
                    <img src={ addHighlighted ? addButton : addDisabled } alt="" className="m-1" onClick={ () => { if (addHighlighted) navigate('/token-add/') } } role="button" />
                </div>
            </div>
        </>
    )
}

export default Profile
